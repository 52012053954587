import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #10a592;
  padding: 50px 20px;
  align-items: center;
  justify-content: center;
  p {
    font-size: 30px;
    color: #fff;
    @media screen and (max-width: 540px) {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 540px) {
    padding: 30px 15px;
  }
`;
