import React from "react";
import * as S from "./style";

const Checks = () => {
  return (
    <S.Container>
      <p>Horário de checkin: a partir das 16h</p>
      <p>Horário de checkout: até 15h</p>
    </S.Container>
  );
};
export default Checks;
